import React, { Fragment } from "react";
import { useState, useContext, useEffect, useRef } from "react";
import { Alert, Button, Container } from "react-bootstrap";
import { DeviceContext } from "../components/DeviceContext";
import ResponseComponent from "../components/ResponseComponent";
import sendEmail from "../components/SendEmail";
import { isNilOrEmpty } from "../components/CustomUtility";
import HtmlReactParser from "html-react-parser";
import { useNavigate } from "react-router-dom";
import createCAMSticket from "../components/CreateCAMSTicket";
import { format } from "date-fns";
//import TerminalIdModal from "../components/TerminalIdModal";
import { InitLiveChat, StartLiveChat } from "../components/LiveChatWidget";

import "../chatbubble.css";

export default function IDR() {
  const [idrRequests, setIdrRequests] = useState([]);
  const [currentIdrRequest, setCurrentIdrRequest] = useState(null);
  const [previousIdrRequests, setPreviousIdrRequests] = useState([]);
  const [idrIdrReqApiUrl, setIdrIdrReqApiUrl] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const { deviceInfo, findLabelValue, urlDeviceParams } =
    useContext(DeviceContext);
  const [responseComponentFormData, setResponseComponentFormData] = useState(
    {}
  );
  const [idrFormCollection, setIdrFormCollection] = useState([]);
  const [terminalId, setTerminalId] = useState(undefined);
  const [idrResponseAction, setIdrResponseAction] = useState(undefined);
  const [idrActionResult, setIdrActionResult] = useState(undefined);
  const [creationTime, setCreationTime] = useState(new Date());
  const idrContainerRef = useRef(null);

  let navigate = useNavigate();

  // load IDR request reponse data from API
  useEffect(() => {

    const fetchData = async () => {
      try {
        const response = await fetch(idrIdrReqApiUrl);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setIdrRequests(result);
        setIsLoading(false);
        setCurrentIdrRequest(result[0]); //set first interactive request as current
        setCreationTime(new Date());
        console.log("IdrRequests Loaded  " + result);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching data:", error);
      }
    };

    if (deviceInfo !== undefined && deviceInfo.productId !== undefined) {
      setIdrIdrReqApiUrl(
        process.env.REACT_APP_IMSP_API_ENDPOINT +
        "/interactiveRequests/find/" +
        deviceInfo.countryCode +
        "/" +
        deviceInfo.clientCode +
        "/" +
        deviceInfo.productName +
        "/" +
        deviceInfo.languageCode
      );

      if (idrIdrReqApiUrl !== undefined) {
        fetchData();
      }
    }
  }, [idrIdrReqApiUrl, deviceInfo]);

  function handleIdrResponseClick(idrResponse) {
    const updateCurrentIdrRequest = {
      //    idrResponseId: currentIdrRequests.idrResponseId,
      idrRequestId: currentIdrRequest.idrRequestId,
      requestText: currentIdrRequest.requestText,
      responseText:
        idrResponse.responseType === "MultipleChoice"
          ? idrResponse.responseText
          : idrResponse.userResponseText,

      //context: currentIdrRequests.artifactLink
    };

    setPreviousIdrRequests([...previousIdrRequests, updateCurrentIdrRequest]);

    // const currentIdrResponse = {
    //   idrResponseId: idrResponse.idrResponseId,
    //   idrRequestId: idrResponse.idrRequestId,
    //   requestText: currentIdrRequests.requestText,
    //   responseType: idrResponse.responseType,
    //   actionType: idrResponse.actionType,
    //   nextIdrRequestId: idrResponse.nextIdrRequestId,

    //   userResponseText: idrResponse.userResponseText,

    //   responseText:
    //     idrResponse.responseType === "MultipleChoice"
    //       ? idrResponse.responseText
    //       : idrResponse.userResponseText

    //   //context: currentIdrRequests.artifactLink
    // };

    //Set next idr request
    if (idrResponse.nextIdrRequestId !== null) {
      let nextIdrReq = idrRequests.find(
        (rq) => rq.idrRequestId === idrResponse.nextIdrRequestId
      );

      if (nextIdrReq !== undefined) {
        const index = nextIdrReq.idrResponses.findIndex(
          (rs) => rs.responseText === "Terminal ID"
        );

        if (index !== -1) {
          nextIdrReq.idrResponses[index] = {
            ...nextIdrReq.idrResponses[index],
            defaultValue: deviceInfo.terminalId, // Update the desired field value
          };
        }

        setCurrentIdrRequest(nextIdrReq);
      }
    } else {
      // End of decision tree
      console.log("Final response:", idrResponse);
    }

    setIdrResponseAction(idrResponse);
  }

  useEffect(() => {
    if (
      idrResponseAction === undefined ||
      idrResponseAction.actionType === undefined
    ) {
      return;
    }

    if (idrResponseAction.actionType === "None") {
      console.log("Action Type:None");
    } else if (idrResponseAction.actionType === "HomePage") {
      console.log("Action Type:HomePage");
      navigate("/home/" + urlDeviceParams);
    } else if (idrResponseAction.actionType === "CreateCAMSTicket") {
      //generate a modal popup to get the user to enter the terminalId
      if (!isNilOrEmpty(deviceInfo.terminalId) || !isNilOrEmpty(terminalId)) {
        const ticketNo = handleCreateCAMSTicket();
        console.log("Action Type:CreateCAMSTicket:" + ticketNo);
      }
    } else if (idrResponseAction.actionType === "EmailClient") {
      handleSendEmail();
      console.log("Action Type:EmailClient");
    } else if (idrResponseAction.actionType === "Reset") {
      //clear previous responses
      setPreviousIdrRequests([]);
      //clear formCollection
      setResponseComponentFormData({});
      setIdrActionResult(undefined);
      // setFormCollection({});
      console.log("Action Type:Reset");
    } else if (idrResponseAction.actionType === "ClearResponses") {
      setPreviousIdrRequests([]);
      setResponseComponentFormData({});
      setIdrActionResult(undefined);
      //setFormCollection({}); do not clear formCollection
      console.log("Action Type:ClearResponses");
    } else if (idrResponseAction.actionType === "LiveChat") {
      handleLiveChat();
      setIdrActionResult(undefined);
      console.log("Action Type:LiveChat");
    }
  }, [idrResponseAction]);

  useEffect(() => {
    idrContainerRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [idrResponseAction]); // For initial scroll to bottom



  function handleIdrResponseSubmit(idrResponse) {
    var userResponseText = undefined;
    // Iterate through formData
    Object.entries(responseComponentFormData).forEach(([key, value]) => {
      //find the idrResponseId that matches the key in currentIdrRequests.idrResponses
      var idrResponseItem = currentIdrRequest.idrResponses.find(
        (r) => r.idrResponseId === parseInt(key)
      );

      //append the responseText and value in userResponseText
      if (idrResponseItem !== undefined) {
        userResponseText = isNilOrEmpty(userResponseText)
          ? idrResponseItem.responseText + ": " + value
          : userResponseText +
          "; " +
          idrResponseItem.responseText +
          ": " +
          value;

        // copy idrResponseItem.responseText and value to formCollection in to array idrFormCollection as key value pair
        if (!isNilOrEmpty(userResponseText)) {
          setIdrFormCollection((prevIdrFormCollection) => [
            ...prevIdrFormCollection,
            { key: idrResponseItem.responseText, value: value },
          ]);
        }

        if (
          idrResponseItem.responseText == "Terminal ID" &&
          !isNilOrEmpty(value)
        ) {
          setTerminalId(value);
        }

        console.log(`${key}: ${value}`);
      }
    });

    //update current idr response with user response

    const newIdrResponse = {
      idrResponseId: idrResponse.idrResponseId,
      idrRequestId: idrResponse.idrRequestId,
      responseText: idrResponse.responseText,
      responseType: idrResponse.responseType,
      actionType: idrResponse.actionType,
      nextIdrRequestId: idrResponse.nextIdrRequestId,
      userResponseText: userResponseText,
      //context: currentIdrRequests.artifactLink
    };

    handleIdrResponseClick(newIdrResponse);
  }

  const handleCreateCAMSTicket = async () => {
    let ticketNo = ''
    try {
      if (!isNilOrEmpty(deviceInfo.terminalId) || !isNilOrEmpty(terminalId)) {
        const camsTicketData = {
          countryCode: deviceInfo.countryCode,
          clientCode: deviceInfo.clientCode,
          deviceName: deviceInfo.productName,
          languageCode: deviceInfo.languageCode,
          terminalId: isNilOrEmpty(deviceInfo.terminalId)
            ? terminalId
            : deviceInfo.terminalId,
          IdrReqRespCollection: previousIdrRequests,
          IdrFormCollection: idrFormCollection,
        };

        setIdrActionResult("Creating Service ticket...");
        ticketNo = await createCAMSticket(camsTicketData);
        setIdrActionResult("Created ticket no: " + ticketNo);
        console.log("Created CAMS Ticket:", ticketNo);
      }
      else{
        setIdrActionResult("Unable to create service ticket without TermninalId!");
      }
    } catch (error) {
      console.log(error.message);
      setIdrActionResult("Unable to create service ticket!");
    }
    return ticketNo;
  };

  //async function to send email
  const handleSendEmail = async () => {
    const recipientEmail = "idrSupport@ingenico.com";
    const emailSubject = "Support request from Ingenico IDR";

    const emailBody = ` ${previousIdrRequests.length > 0
      ? `<b>Previous Responses:</b>
      <ul id="idPreviousIdrRequests">
        ${previousIdrRequests
        .map(
          (reqResp) => `
          <li key=${reqResp.idrRequestId}>
            ${reqResp.requestText} <b>${reqResp.responseText}</b>
          </li>`
        )
        .join("")}
      </ul>`
      : ""
      }`;

    const emailData = {
      recipientEmails: recipientEmail,
      subject: emailSubject,
      body: emailBody,
    };

    try {
      const emailResponse = await sendEmail(emailData);
      setIdrActionResult(emailResponse);
    } catch (error) {
      console.log(error.message);
      setIdrActionResult("Unable to send email request!");
    }
  };


  //async function for livechat
  const handleLiveChat = async (clientCode, terminalId) => {
    let ticketNo = ''
    if (!isNilOrEmpty(deviceInfo.terminalId) || !isNilOrEmpty(terminalId)) {
      console.log("Action Type:CreateCAMSTicket");
      ticketNo = handleCreateCAMSTicket();
    }

    const idrData = `${previousIdrRequests.length > 0
      ? `IDR Responses:\n
        ${previousIdrRequests
        .map((reqResp) => `Request: ${reqResp.requestText} \nResponse: ${reqResp.responseText}\n\n`).join("")}`
      : ""}`;

    const contactAttributes = {
      clientID: deviceInfo.clientCode,
      terminalID: isNilOrEmpty(deviceInfo.terminalId)
      ? terminalId
      : deviceInfo.terminalId,
      jobID: ticketNo,
      installerID: idrData,
    };

    let atrributeToken = null;
    try {
      atrributeToken = await InitLiveChat(contactAttributes);
      if (atrributeToken && atrributeToken.length > 0) {
        await StartLiveChat("", atrributeToken);
      }
      setIdrActionResult("Initiated Live Chat!");
    } catch (error) {
      console.log(error.message);
      setIdrActionResult("Unable to initiate Live Chat!");
    }
  };


  //handle ResponseComponent Value Change
  const handleResponseComponentValueChange = (event, idrResponse) => {
    // event.preventDefault();
    const { name, value } = event.target;
    //console.log("handleResponseComponentValueChange", idrResponse);

    if (idrResponse.responseType !== "MultipleChoice") {
      setResponseComponentFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  if (isLoading === true) {
    return (
      <div className="main-content">
        <Container>
          <p>{findLabelValue("pageLoading")}</p>
        </Container>
      </div>
    );
  }

  if (deviceInfo === undefined) {
    return (
      <div>
        <p>{findLabelValue("pageFaqNotFound")}</p>
      </div>
    );
  }

  return (
    <div className="main-content">
      <br />
      {!isLoading && (
        <Container>
          <p className="align-self-center text-center ingenico-color-darkblue">
            {format(creationTime, "dd/MM/yyyy HH:mm")}
          </p>
          {/* Display previous request and responses */}
          {previousIdrRequests.length !== 0 && (

            <div className="row position-relative">
              <ul id="idPreviousIdrRequests" className="list-unstyled">
                {previousIdrRequests.map((reqResp) => (
                  <li
                    key={reqResp.idrRequestId}
                    className="row position-relative"
                  >
                    <div className="balon2">
                      <a className="float-start">
                        {HtmlReactParser(reqResp.requestText)}
                      </a>
                    </div>
                    <div className="balon1">
                      <a className="float-end">{HtmlReactParser(reqResp.responseText)} </a>
                    </div>
                  </li>
                ))}
              </ul>

              {/* <ul id="idPreviousIdrRequests">
                {previousIdrRequests.map((reqResp) => (
                  <li key={reqResp.nodeRequestId}>
                    {HtmlReactParser(reqResp.requestText)}{" "}
                    <Badge bg="secondary">{reqResp.responseText}</Badge>
                  </li>
                ))}
              </ul> */}
              {/*If there is a action resuly display the Action result */}
              {!isNilOrEmpty(idrActionResult) && (
                <Alert variant="secondary ">{idrActionResult}</Alert>
              )}
              <br />
            </div>
          )}

          {/* display idr request responses */}
          {currentIdrRequest !== undefined &&
            currentIdrRequest.requestText !== undefined ? (
            <div>
              {/* display idr request */}
              {/* left bubble*/}

              <div className="balon2 p-2 row position-relative">
                <a className=" mb-0 float-start">
                  {HtmlReactParser(currentIdrRequest.requestText)}
                </a>
              </div>
              {!isNilOrEmpty(currentIdrRequest.artifactLink) && (
                <a
                  className="float-start"
                  style={{ whiteSpace: "pre-wrap" }}
                  href={currentIdrRequest.artifactLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Artifact Link
                </a>
              )}{" "}
              <br></br>

              {/* display node request */}
              {/* <Alert variant="secondary ">
                {HtmlReactParser(currentIdrRequest.requestText)}
                {!isNilOrEmpty(currentIdrRequest.artifactLink) && <br />}
                {!isNilOrEmpty(currentIdrRequest.artifactLink) && (
                  <a
                    style={{ whiteSpace: "pre-wrap" }}
                    href={currentIdrRequest.artifactLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Artifact Link
                  </a>
                )}
              </Alert> */}

              {/* display idr responses */}
              {currentIdrRequest.idrResponses.length !== 0 && (
                <div>
                  <ul id="idCurrentIdrRequests">
                    {/* we have two fragments of li */}
                    {currentIdrRequest.idrResponses.map(
                      (idrResponse, index) => (
                        <Fragment key={idrResponse.idrResponseId}>
                          <li
                            key={idrResponse.idrResponseId}
                            onClick={
                              idrResponse.responseType === "MultipleChoice"
                                ? () => handleIdrResponseClick(idrResponse)
                                : undefined
                            }
                            className={
                              idrResponse.responseType === "MultipleChoice"
                                ? ""
                                : "no-bullets mb-2"
                            }
                          >
                            <ResponseComponent
                              idrResponse={idrResponse}
                              onValueChange={handleResponseComponentValueChange}
                            />
                          </li>

                          {/* Finally add submit button for non multiple choice */}
                          {index ===
                            currentIdrRequest.idrResponses.length - 1 &&
                            idrResponse.responseType !== "MultipleChoice" && (
                              <li
                                key={"99" + idrResponse.idrResponseId}
                                className="no-bullets mb-2"
                              >
                                <Button
                                  variant="secondary"
                                  type="submit"
                                  onClick={() =>
                                    handleIdrResponseSubmit(idrResponse)
                                  }
                                >
                                  {findLabelValue("pageIDRSubmitButton")}
                                </Button>
                              </li>
                            )}

                          {/* <TerminalIdModal
                            show={showTerminalIdModal}
                            handleClose={handleCloseTerminalIdModal}
                          /> */}
                        </Fragment>
                      )
                    )}
                  </ul>
                  <div ref={idrContainerRef}>
                    <br></br>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div>
              No assistance available. Please call us for assistance on {findLabelValue("pageSupportTelephoneNo")}
            </div>
          )}
        </Container>
      )}
    </div>
  );
}
