import { createContext, useState, useEffect } from "react";
import { DefaultLabels } from "./DefaultLabels";
import PropTypes from "prop-types";
import { isNilOrEmpty } from "./CustomUtility";
//import { useNavigate, useParams, useLocation } from "react-router-dom";

export const DeviceContext = createContext(null);

const DefaultDeviceInfo = {
  productId: undefined,
  countryCode: undefined,
  clientCode: undefined,
  productName: undefined,
  languageCode: undefined,
  terminalId: undefined,
  availableLanguages: undefined,
  localisedLabels: undefined,
  isLoaded: false
};

export default function DeviceContextProvider({ children }) {
  DeviceContextProvider.propTypes = {
    children: PropTypes.node.isRequired
  };

  const [deviceInfo, setDeviceInfo] = useState(DefaultDeviceInfo);
  const [isLoading, setIsLoading] = useState(true);
  const [languageSelected, setLanguageSelected] = useState(undefined);
  const [urlDeviceParams, setUrlDeviceParams] = useState(undefined);
  const [apiClientURL, setApiClientURL] = useState(undefined);
  // const navigate = useNavigate();
  //       if(urlDeviceParams !== undefined)
  //     navigate("home/" + urlDeviceParams, { replace: true });


  useEffect(() => {

    const loadClientDeviceInfo = () => {
      console.log(apiClientURL);
      fetch(apiClientURL)
        .then((response) => response.json())
        .then((result) => {
          setIsLoading(false);
  
          let tempClientDeviceCntx = result; //JSON.parse(result);
  
          if (
            languageSelected !== undefined &&
            tempClientDeviceCntx.availableLanguages.includes(languageSelected)
          ) {
            //keep selected languageCode
            tempClientDeviceCntx.languageCode = languageSelected;
          }
  
          let tempUrlParam =
            "?countryCode=" +
            tempClientDeviceCntx.countryCode +
            "&clientCode=" +
            tempClientDeviceCntx.clientCode +
            "&productName=" +
            tempClientDeviceCntx.productName;
  
          if (
            tempClientDeviceCntx.languageCode !== undefined &&
            tempClientDeviceCntx.languageCode !== null &&
            tempClientDeviceCntx.languageCode !== "" &&
            tempClientDeviceCntx.languageCode !== "undefined"
          ) {
            tempUrlParam += "&languageCode=" + tempClientDeviceCntx.languageCode;
          } else {
            tempUrlParam += "&languageCode=";
          }
  
          if (
            tempClientDeviceCntx.terminalId !== undefined &&
            tempClientDeviceCntx.terminalId !== null &&
            tempClientDeviceCntx.terminalId !== "" &&
            tempClientDeviceCntx.terminalId !== "undefined"
          )
            tempUrlParam += "&terminalId=" + tempClientDeviceCntx.terminalId;
  
          setUrlDeviceParams(tempUrlParam);
  
          if (
            tempClientDeviceCntx !== null &&
            tempClientDeviceCntx.localisedLabels !== null &&
            Object.keys(tempClientDeviceCntx.localisedLabels).length === 0
          ) {
            // console.log("Home tempClientDeviceCntx  " + JSON.stringify(tempClientDeviceCntx));
            //if not found set to default
            tempClientDeviceCntx.localisedLabels = DefaultLabels;
          }
  
          setDeviceInfo(tempClientDeviceCntx);
        });
    };

    if (apiClientURL !== undefined) 
      loadClientDeviceInfo();
    
  }, [apiClientURL, languageSelected]);

  const findLabelValue = (labelName) => {
    if (
      deviceInfo !== undefined &&
      deviceInfo.localisedLabels !== undefined
    ) {
      var localisedLabel = deviceInfo.localisedLabels.filter(
        (entry) => entry.labelName === labelName
      );

      if (typeof localisedLabel === "object") {
        return localisedLabel.map(({ labelValue }) => labelValue);
      } else return "Label no found";
    }
  };

  const onLanguageOptionSelected = (value) => {
    console.log("Language selected " + value);

    setLanguageSelected(value);

    setAPIParams(
      deviceInfo.countryCode,
      deviceInfo.clientCode,
      deviceInfo.productName,
      value,
      deviceInfo.terminalId
    );
  };

  const setAPIParams = (
    countryCode,
    clientCode,
    productName,
    languageCode,
    terminalId
  ) => {
    let apiURLTemp = undefined;

    if (
      countryCode !== undefined &&
      clientCode !== undefined &&
      productName !== undefined
    ) {
      apiURLTemp =
        process.env.REACT_APP_IMSP_API_ENDPOINT +
        "/products/find/" +
        countryCode +
        "/" +
        clientCode +
        "/" +
        productName;

      if (!isNilOrEmpty(languageCode)) {
        apiURLTemp = apiURLTemp + "/" + languageCode;

        if (!isNilOrEmpty(terminalId)) {
          apiURLTemp = apiURLTemp + "/" + terminalId;
        }
      }

      //update deviceInfo
      setDeviceInfo(
        countryCode,
        clientCode,
        productName,
        languageCode,
        terminalId
      );
      setApiClientURL(apiURLTemp);
    }
  };

  const value = {
    deviceInfo,
    // setDeviceInfo,
    isLoading,
    //setIsLoading,
    languageSelected,
    // setLanguageSelected,
    urlDeviceParams,
    //setUrlDeviceParams,
    apiClientURL,
    setApiClientURL,
    setAPIParams,

    // loadClientDeviceInfo,
    findLabelValue,
    onLanguageOptionSelected
  };

  return (
    <DeviceContext.Provider value={value}>{children}</DeviceContext.Provider>
  );
}
