import Card from "react-bootstrap/Card";
//import { useNavigate } from "react-router-dom";
import TerminalDevice from "../components/TerminalDevice";
import Image from "react-bootstrap/Image";
// import { Button } from "react-bootstrap";
// import { JournalText, Headset } from "react-bootstrap-icons";

import { useState, useContext } from "react";
import { DeviceContext } from "../components/DeviceContext";
import SupportModal from "../components/SupportModal";

export default function Home() {
  const [showSupportModal, setShowSupportModal] = useState(false);
  // const { deviceInfo, isLoading, findLabelValue , urlDeviceParams } =
  //   useContext(DeviceContext);

    const { deviceInfo, isLoading, findLabelValue } =
    useContext(DeviceContext);

  //let navigate = useNavigate();

  if (isLoading === true) {
    if (deviceInfo === undefined) {
      return (
        <div>
          <p>Loading...</p>
        </div>
      );
    } else if (deviceInfo !== undefined) {
      //show in native language
      return (
        <div>
          <p>{findLabelValue("pageLoading")}</p>
        </div>
      );
    }
  }

  if (isLoading === false && deviceInfo === undefined) {
    return (
      <div>
        <p>{findLabelValue("pageDeviceNotFound")}</p>
      </div>
    );
  }

  return (
    <div className="main-content">
      <div className="card text-white text-center" style={{ border: 0 }}>
        <Image src="/images/ingenico/homebackground.png" height={200}/>
        <Card.ImgOverlay>
          <Card.Title>
          </Card.Title>
          {/* <Card.Text>
            <Button
              className="button-rounded"
              variant="light"
              onClick={() => {
                navigate("/faqs/" + urlDeviceParams);
              }}
            >
              <JournalText size={24} />{" "}
              <small className="button-text" style={{ paddingRight: "56px" }}>
                {findLabelValue("pageHomeFaqButton")}
              </small>
            </Button>

            <Button
              className="ms-3  button-rounded"
              variant="light"
              onClick={() => {
                //    setShowSupportModal(true); old popup
                navigate("/idr/" + urlDeviceParams);
              }}
            >
              <Headset size={24} />{" "}
              <small className="button-text">
                {findLabelValue("pageHomeGetassistance")}
              </small>
            </Button>
          </Card.Text> */}
          <br></br>
          <Card.Title>
            <h2>
              <b>{findLabelValue("pageHomeTitle")}</b>
            </h2>
          </Card.Title>
          <Card.Text>
            <b>{findLabelValue("pageHomePassage")}</b>
          </Card.Text>
          <Card.Text>
            <br></br>
          </Card.Text>
        </Card.ImgOverlay>
      </div>
      <SupportModal
        show={showSupportModal}
        onHide={() => setShowSupportModal(false)}
      />
      <div>
        {/* <Card className="shadow m-1"> */}
        <Card className="border-0">
          {<Card.Body>{<TerminalDevice />}</Card.Body>}
        </Card>
        <br></br>
      </div>
    </div>
  );
}
