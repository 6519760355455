import Container from "react-bootstrap/Container";
import { useState, useContext, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faKeyboard, faCreditCard } from '@fortawesome/free-regular-svg-icons'
import { faBattery4, faDisplay, faPrint, faSignal, faCamera, faMicrochip, faMusic, faWifi, faNetworkWired } from '@fortawesome/free-solid-svg-icons'
import { faBluetoothB, faSpotify } from '@fortawesome/free-brands-svg-icons'


import { DeviceContext } from "../components/DeviceContext";

export default function Diags() {
  const [isLoading, setIsLoading] = useState(true);
  const [terminalDiags, setTerminalDiags] = useState(undefined);
  const { deviceInfo, findLabelValue } = useContext(DeviceContext);


  useEffect(() => {

    const fetchTerminalDiags = async () => {
      let terminalDiagsUri = process.env.REACT_APP_IMSP_API_ENDPOINT +
        "/IngenicoEM/terminalDiags/" + deviceInfo.terminalId;

      try {
        const response = await fetch(terminalDiagsUri);
        const json = await response.json();
        setTerminalDiags(json);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching terminal diags:", error);
      }
    }


    if (deviceInfo !== null && deviceInfo.terminalId !== undefined) {
      fetchTerminalDiags();
    }


  }, [deviceInfo]);


  const StatusColour = (status) => {
    console.log(status);
    if(status === "SUCCESS")
      return "lightgreen";
    else if( status === "FAILURE" )
      return "red";
    else
      return "gray"


  }


  if (deviceInfo === undefined) {
    return (
      <div>
        <p>{findLabelValue("pageFaqNotFound")}</p>
      </div>
    );
  }

  if (isLoading === true) {
    return (
      <div>
        <p>{findLabelValue("pageLoading")}</p>
      </div>
    );
  }

  return (
    <div className="main-content">
      <br></br>
      {!isLoading && terminalDiags && (
        <Container>
          <div className="small ingenico-color-darkblue">
            <Row>
              <Col xs={6} className="text-start">
                <b className="ingenico-color-darkblue">Diagnostics</b>
              </Col>
              <Col xs={6} className="text-end">
                {terminalDiags.terminalDiag.lastTestDate} 
              </Col>
            </Row>
            <br></br>
            <Card >
              <Card.Body>
                <Card.Text>
                  <Row>
                    <Col xs={6} className="text-start">
             <FontAwesomeIcon icon={faBattery4}  style={{color: StatusColour(terminalDiags.terminalDiag.battery)}} /> &nbsp;
                      <b>Battery</b>
                    </Col>
                    <Col xs={6} className="text-start">
             <FontAwesomeIcon icon={faKeyboard}  style={{color: StatusColour(terminalDiags.terminalDiag.keyboard)}} /> &nbsp;
                      <b>Keyboard</b>
                    </Col>
                  </Row>
                  <br></br>
                  <Row>
                    <Col xs={6} className="text-start">
             <FontAwesomeIcon icon={faCreditCard}  style={{color: StatusColour(terminalDiags.terminalDiag.mStripeReader)}} /> &nbsp;
                      <b>Magnetic Card</b>
                    </Col>
                    <Col xs={6} className="text-start">
             <FontAwesomeIcon icon={faDisplay}  style={{color: StatusColour(terminalDiags.terminalDiag.display)}} /> &nbsp;
                      <b>Display</b>
                    </Col>
                  </Row>
                  <br></br>
                  <Row>
                    <Col xs={6} className="text-start">
             <FontAwesomeIcon icon={faBluetoothB}  style={{color: StatusColour(terminalDiags.terminalDiag.bluetooth)}} /> &nbsp;
                      <b>Bluetooth</b>
                    </Col>
                    <Col xs={6} className="text-start">
             <FontAwesomeIcon icon={faPrint}  style={{color: StatusColour(terminalDiags.terminalDiag.printer)}} /> &nbsp;
                      <b>Printer </b>
                    </Col>
                  </Row>  
                  <br></br>
                  <Row>
                    <Col xs={6} className="text-start">
             <FontAwesomeIcon icon={faSignal}  style={{color: StatusColour(terminalDiags.terminalDiag.cellular)}} /> &nbsp;
                      <b>Mobile Network</b>
                    </Col>
                    <Col xs={6} className="text-start">
             <FontAwesomeIcon icon={faCamera}  style={{color: StatusColour(terminalDiags.terminalDiag.camera)}} /> &nbsp;
                      <b>Camera</b>
                    </Col>
                  </Row> 
                  <br></br>
                  <Row>
                    <Col xs={6} className="text-start">
             <FontAwesomeIcon icon={faMicrochip}  style={{color: StatusColour(terminalDiags.terminalDiag.chipReader)}} /> &nbsp;
                      <b>Smartcard</b>
                    </Col>
                    <Col xs={6} className="text-start">
             <FontAwesomeIcon icon={faMusic}  style={{color: StatusColour(terminalDiags.terminalDiag.audio)}} /> &nbsp;
                      <b>Audio </b>
                    </Col>
                  </Row> 
                  <br></br>
                  <Row>
                    <Col xs={6} className="text-start">
             <FontAwesomeIcon icon={faWifi}  style={{color: StatusColour(terminalDiags.terminalDiag.wifi)}} /> &nbsp;
                      <b>WiFi</b>
                    </Col>
                    <Col xs={6} className="text-start">
             <FontAwesomeIcon icon={faSpotify} rotation={90} style={{color: StatusColour(terminalDiags.terminalDiag.clessReader)}} /> &nbsp;
                      <b>Contactless </b>
                    </Col>
                  </Row>  
                  <br></br>
                  <Row>
                    <Col xs={6} className="text-start">
             <FontAwesomeIcon icon={faNetworkWired}  style={{color: StatusColour(terminalDiags.terminalDiag.ethernet)}} /> &nbsp;
                      <b>Ethernet</b>
                    </Col>
                    <Col xs={6} className="text-start">

                    </Col>
                  </Row>                                     

                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </Container>
      )}
    </div>
  );
}
