import { useState, React, useContext } from "react";
import Accordion from "react-bootstrap/Accordion";
import PropTypes from "prop-types";
import HtmlReactParser from "html-react-parser";
import { DeviceContext } from "./DeviceContext";
//import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack5";
// import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
//import useResizeObserver from "@react-hook/resize-observer";
import axios from "axios";
import PdfView from "./PdfView";

export default function FAQItem({ faq }) {
  FAQItem.propTypes = {
    faq: PropTypes.object.isRequired
  };

  // pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  //const [numPages, setNumPages] = useState(null);
  //const [pageNumber] = useState(1);
  const [pdfData, setPdfData] = useState(null);

  // function onDocumentLoadSuccess({ numPages }) {
  //   setNumPages(numPages);
  // }

  //   const goToPrevPage = () =>
  //   setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

  // const goToNextPage = () =>
  //   setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);

  const { findLabelValue } = useContext(DeviceContext);

  const fetchPdfData = async () => {
    if (
      faq.artifactLink !== null &&
      (faq.artifactLink.substr(faq.artifactLink.length - 4) === ".pdf") === true
    ) {
      let pdfuri =
        process.env.REACT_APP_IMSP_API_ENDPOINT +
        "/documents/pdfdoc?pdfurl=" +
        faq.artifactLink;

      try {
        const response = await axios.get(pdfuri, {
          headers: { "Content-Type": "application/pdf" },
          responseType: "arraybuffer"
        });
        setPdfData(response.data);
      } catch (error) {
        console.error(error);
      }
    }
  };

  function onClickAccordion(e) {
    if (e !== null) fetchPdfData();
  }

  if (!pdfData) {
    // fetchPdfData();
    // return <p>Loading PDF file...</p>;
  }

  if (faq === null) {
    return (
      <div>
        <p> {findLabelValue("pageFaqNotFound")}</p>
      </div>
    );
  } else
    return (
      <div>
        <Accordion.Header onClick={() => onClickAccordion(faq.title)}>
          <b className="ingenico-color-darkblue"> {faq.title} </b>
        </Accordion.Header>
        {faq.artifactLink !== null ? (
          <Accordion.Body className="ingenico-color-darkblue">
            {faq.body !== null && HtmlReactParser(faq.body)}
            {faq.body !== null &&
              faq.body !== "" &&
              HtmlReactParser("<br><br>")}

            {(faq.artifactLink.substr(faq.artifactLink.length - 4) ===
              ".pdf") ===
            true ? (
              <div>
                {/* <Document
                  file={{ data: pdfData }}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page pageNumber={pageNumber} renderTextLayer={false} />
                </Document>
                <p>
                  Page {pageNumber} of {numPages}
                </p> */}

                {/* <Document
                  file={{ data: pdfData }}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  {Array.from(new Array(numPages), (el, index) => (
                    <Page
                      width={document.getElementById("root").clientWidth * 0.82}
                      // scale={80.0}
                      key={"page_" + index + 1}
                      pageNumber={index + 1}
                      renderTextLayer={false}
                    />
                  ))}
                </Document> */}

                {pdfData && <PdfView pdfData={pdfData} />}
              </div>
            ) : (
              <iframe
                title={faq.title}
                src={faq.artifactLink}
                frameBorder="0"
                autoPlay
                allowFullScreen
                // picture-in-picture="true"
                style={{
                  width: "1px",
                  minWidth: "100%",
                  minHeight: "240px"
                }}
              ></iframe>
            )}
          </Accordion.Body>
        ) : (
          <Accordion.Body>{HtmlReactParser(faq.body)}</Accordion.Body>
        )}
      </div>
    );
}
