import { React } from "react";
import {Container, Col, Row} from "react-bootstrap";
import Image from "react-bootstrap/Image";
import ListGroup from "react-bootstrap/ListGroup";
import Accordion from 'react-bootstrap/Accordion';

import { useState, useContext, useEffect } from "react";
import { DeviceContext } from "./DeviceContext";

export default function TerminalDevice() {
  const { deviceInfo, findLabelValue } = useContext(DeviceContext);
  const [imageData, setImageData] = useState(null);
  const [terminalInfo, setTerminalInfo] = useState(null);

  useEffect(() => {
    const fetchTerminalImage = async () => {
      let imageUri = process.env.REACT_APP_IMSP_API_ENDPOINT +
        "/products/image/" + deviceInfo.productId;

      fetch(imageUri)
        .then((response) => response.blob())
        .then((data) => setImageData(URL.createObjectURL(data)))
        .catch((error) => {
          console.error("Error retrieving image data:", error);
        });
    }


    const fetchTerminalInfo = async () => {
      let terminalInfoUri = process.env.REACT_APP_IMSP_API_ENDPOINT +
        "/IngenicoEM/terminalInfo/" + deviceInfo.terminalId;

      fetch(terminalInfoUri)
        .then((response) => response.json())
        .then((result) => setTerminalInfo(result))
        .catch((error) => {
          console.error("Error retrieving terminal info:", error);
        });
    }

    if (deviceInfo !== null && deviceInfo.productId !== undefined) {
      fetchTerminalImage();
    }

    if (deviceInfo !== null && deviceInfo.terminalId !== undefined) {
      fetchTerminalInfo();
    }


  }, [deviceInfo]);

  return (
    <div>
      {deviceInfo ? (
        <Container>
          <Row>
            <Col xs={9}>
              <br></br>
              <br></br>
              <ListGroup variant="flush" >
                <ListGroup.Item className="ingenico-color-darkblue">
                  <b className="ingenico-color-darkblue">{findLabelValue("pageHomeTerminalDeviceType")}:</b>{" "}
                  {deviceInfo.productName}
                </ListGroup.Item>
                <ListGroup.Item className="ingenico-color-darkblue">
                  <b>{findLabelValue("pageHomeTerminalClientID")}:</b>{" "}
                  {deviceInfo.clientCode}
                </ListGroup.Item>
                {/* <ListGroup.Item>
                <b>{findLabelValue("pageHomeTerminalCountry")}:</b> {deviceInfo.countryCode}
              </ListGroup.Item>
              <ListGroup.Item>
                <b>{findLabelValue("pageHomeTerminalLanguage")}:</b> {deviceInfo.languageCode}
              </ListGroup.Item> */}
              </ListGroup>
            </Col>
            <Col xs={3} className="ml-auto">
              <br></br>
              <Image src={imageData} width="60" alt="terminal" />
            </Col>
          </Row>
          <Row><Col><br></br></Col></Row>
          <Row>
            <Col>

              {(terminalInfo != null && terminalInfo.terminalInfo != null) ?
                <Accordion collapse>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header><b className="ingenico-color-darkblue">More Information</b></Accordion.Header>
                    <Accordion.Body className="smallFontSize">
                    <div className="ingenico-color-darkblue">
                      <Row>
                        <Col xs={7} className="text-start">
                          <b>Serial Number</b>
                        </Col>
                        <Col xs={5} className="text-end">
                          {terminalInfo.terminalInfo.serialNumber}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={7} className="text-start">
                          <b>IP Address</b>
                        </Col>
                        <Col xs={5} className="text-end">
                          {terminalInfo.terminalInfo.localIpAddress}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={7} className="text-start">
                          <b>Battery Level</b>
                        </Col>
                        <Col xs={5} className="text-end">
                          {terminalInfo.terminalInfo.batteryLevel}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={7} className="text-start">
                          <b>Batt Charge Cycles</b>
                        </Col>
                        <Col xs={5} className="text-end">
                          {terminalInfo.terminalInfo.batteryCycle}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={7} className="text-start">
                          <b>Flash Used</b>
                        </Col>
                        <Col xs={5} className="text-end">
                          {terminalInfo.terminalInfo.flashUsage}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={7} className="text-start">
                          <b>RAM Used</b>
                        </Col>
                        <Col xs={5} className="text-end">
                          {terminalInfo.terminalInfo.ramUsage}
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={7} className="text-start">
                          <b>Contactless Failures</b>
                        </Col>
                        <Col xs={5} className="text-end">
                          {terminalInfo.terminalInfo.clessFailure}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={7} className="text-start">
                          <b>Contactless Success</b>
                        </Col>
                        <Col xs={5} className="text-end">
                          {terminalInfo.terminalInfo.clessSuccess}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={7} className="text-start">
                          <b>Insert Failure</b>
                        </Col>
                        <Col xs={5} className="text-end">
                          {terminalInfo.terminalInfo.iccFailure}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={7} className="text-start">
                          <b>Insert Success</b>
                        </Col>
                        <Col xs={5} className="text-end">
                          {terminalInfo.terminalInfo.iccSuccess}
                        </Col>
                      </Row>    
                      <Row>
                        <Col xs={7} className="text-start">
                          <b>No. of Card Swipes</b>
                        </Col>
                        <Col xs={5} className="text-end">
                          {terminalInfo.terminalInfo.magCardSwipe}
                        </Col>
                      </Row> 
                      <Row>
                        <Col xs={7} className="text-start">
                          <b>No. of Screen Taps</b>
                        </Col>
                        <Col xs={5} className="text-end">
                          {terminalInfo.terminalInfo.lcdClick}
                        </Col>
                      </Row>  
                      </div>                                                                                           
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                : ''
              }

            </Col>
          </Row>
        </Container>
      ) : (
        findLabelValue("pageDeviceNotFound")
      )}
    </div>
  );
}
