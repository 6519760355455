import React, { useEffect } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Headset, HouseDoor, ListCheck, JournalText } from "react-bootstrap-icons";
import { NavLink, useLocation, useNavigate  } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { useState, useContext } from "react";
import { DeviceContext } from "./DeviceContext";
import SupportModal from "./SupportModal";
import queryString from "query-string";

export default function Footer() {
  const {
    deviceInfo,
    setAPIParams,
    // isLoading,
    //findLabelValue,
    urlDeviceParams
  } = useContext(DeviceContext);

  const [showSupportModal, setShowSupportModal] = useState(false);
  const { pathname, search } = useLocation();
  //const routes = null;

  let parms = {
    countryCode: "",
    clientCode: "",
    productName: "",
    languageCode: "",
    terminalId: ""
  };

  //const { countryCode, clientCode, productName, languageCode } = useParams();

  useEffect(() => {
    //const searchParams = new URLSearchParams(search);
    if (search === "") {
      const paramsObj = pathname.split("/");

      parms.countryCode = paramsObj[2];
      parms.clientCode = paramsObj[3];
      parms.productName = paramsObj[4];
      parms.languageCode = paramsObj[5];
      parms.terminalId = paramsObj[6];
    } else {
      //explaining below code
      //https://stackoverflow.com/questions/8648892/convert-url-parameters-to-a-javascript-object
      parms = queryString.parse(search);
    }
  }, [location.search]);

  useEffect(() => {
    setAPIParams(
      parms.countryCode,
      parms.clientCode,
      parms.productName,
      parms.languageCode,
      parms.terminalId
    );
  }, []);


  const navigate = useNavigate();
  //force reload of IDR page  
  const handleIDRReload = () => {
    console.log("Reloading IDR");
    deviceInfo && urlDeviceParams
      ? navigate("/idr" + urlDeviceParams, { reload: true })
      : navigate("/idr", { reload: true });

      window.location.reload();
  };


  return (
    <div className="footer">
      <Navbar fixed="bottom" className="ingenico-background-white top-shadow">
        <Container>
          <Nav className="container navbar">
            <NavLink
              activeclassname="nlink-active"
              className={({ isActive }) =>
                isActive ? "nlink-active" : "nlink-inactive"
              }
              to={
                deviceInfo && urlDeviceParams
                  ? "/home" + urlDeviceParams
                  : "/home"
              }
            >
              <HouseDoor size={32} className="" />
              {/* <small className="ms-1 d-block">
                {findLabelValue("footerHomeTitle")}
              </small> */}
            </NavLink>

            <NavLink
              activeclassname="nlink-active"
              className={({ isActive }) =>
                isActive ? "nlink-active" : "nlink-inactive"
              }
              to={
                deviceInfo && urlDeviceParams
                  ? "/diags" + urlDeviceParams
                  : "/diags"
              }
            >
              <ListCheck size={32} className="" />

            </NavLink>            

            <NavLink
              activeclassname="nlink-active"
              className={({ isActive }) =>
                isActive ? "nlink-active" : "nlink-inactive"
              }
              to={
                deviceInfo && urlDeviceParams
                  ? "/faqs" + urlDeviceParams
                  : "/faqs"
              }
            >
              <JournalText size={32} className="" />
            </NavLink>
            <NavLink id="idrHeadSetIconID"
              activeclassname="nlink-active"
              className={({ isActive }) =>
                isActive ? "nlink-active" : "nlink-inactive"
              }
              to={
                deviceInfo && urlDeviceParams
                  ? "/idr" + urlDeviceParams
                  : "/idr"
              }

              onClick={handleIDRReload}
              // onClick={() => {
              //   setShowSupportModal(true);
              // }}
              // to={pathname + urlDeviceParams}
            >
              <Headset size={32} className="" />
            </NavLink>
          </Nav>
        </Container>
      </Navbar>
      <SupportModal
        show={showSupportModal}
        onHide={() => setShowSupportModal(false)}
      />
    </div>
  );
}
